import {
  LoginRNRRouteData,
  getLoginRNRRouteData,
} from "~/server/data/login_rnr_route_data";
import { cache, createAsync } from "@solidjs/router";
import { Accessor, createMemo, Match, onMount, Switch } from "solid-js";
import { rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";
import { config } from "~/data/config";
import LoginRnrComponentV1 from "~/components/login-rnr/login_rnr_component";
import LoginRnrAxisComponent from "~/components/login-rnr/login_rnr_axis_component";

const getLoginRNRRouteData$C = cache(getLoginRNRRouteData, "login");

export default function LoginRNRRoute() {
  const routeData: Accessor<LoginRNRRouteData | undefined> = createAsync(() =>
    getLoginRNRRouteData$C()
  );

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.clientId === config.axisClientId;
  });

  return (
    <Switch>
      <Match when={isClientAxis()}>
        <LoginRnrAxisComponent routeData={routeData} />
      </Match>
      <Match when={true}>
        <LoginRnrComponentV1
          isClientAxis={isClientAxis}
          routeData={routeData}
        />
      </Match>
    </Switch>
  );
}
