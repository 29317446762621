"use server";

import { getRequestEvent } from "solid-js/web";
import { AuthMethod } from "../types/client";
import { getCookie } from "vinxi/http";
import { Cookie, Mode } from "~/types";
import { redirect } from "@solidjs/router";

export type LoginRNRRouteData = {
  authMethod: AuthMethod;
  clientLogoUrl: string;
  partnerName: string;
  clientId: string;
  clientSecret: string;
  mode: Mode;
};

export const getLoginRNRRouteData = async (): Promise<LoginRNRRouteData> => {
  const requestEvent = getRequestEvent();

  if (requestEvent) {
    let sid = requestEvent?.locals?.sid;

    if (sid) {
      let mode =
        requestEvent?.locals?.mode ??
        (getCookie(requestEvent?.nativeEvent, Cookie.Mode) as Mode);
      if (mode === "rnr") {
        return redirect("/");
      } else if (mode === "procurement") {
        return redirect("/catalogue");
      }
    }

    const configData = requestEvent.locals;

    if (
      configData &&
      configData.clientId &&
      configData.clientSecret &&
      configData.authMethod
    ) {
      return {
        authMethod: configData.authMethod!,
        clientLogoUrl: configData.clientLogoUrl,
        partnerName: configData.partnerName!,
        clientId: configData.clientId!,
        clientSecret: configData.clientSecret!,
        mode: configData.mode as Mode,
      };
    }
  }

  throw new Error("Config data is incomplete");
};
